import styled from 'styled-components';
import BgImage from '../../common/assets/image/map.png';

const PartnerSectionWrapper = styled.section`
  padding-top: 50px;
  paddin-bottom: 0px;
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: 25% center;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  @media (max-width: 575px) {
    padding: 20px 0;
  }
`;

export default PartnerSectionWrapper;
