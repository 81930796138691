import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import RImage from '../../common/components/ResponsiveImage';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';

import PartnerSectionWrapper from './partner.style';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  textArea,
  imageArea,
}) => {

  const DATA = useStaticQuery(graphql`
    query {
      dataJson {
        PARTNERS {
          sectionTitle
          sectionDescription
          buttonText
          buttonURL
          image {
            childImageSharp{
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
        }
      }
    }
  `);

  const { sectionTitle,
    sectionDescription,
    image,
    imageAlt
  } = DATA.dataJson.PARTNERS;

  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea} >
            <RImage fluid={image.childImageSharp.fluid} alt={imageAlt} />
          </Box>
          <Box {...col} {...textArea}>
            <Heading
              {...title}
              content={sectionTitle}
            />
            <Text
              {...description}
              content={sectionDescription}
            />
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.1',
    fontWeight: 'bolder',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    textAling: 'left',
    fontSize: '14px',
    fontWeight: 'bolder',
    color: '#fff',
    colors: 'secondary',
    borderRadius: '4px',
    pl: '50px',
    pr: '50px',
  },
};

export default PartnerSection;
